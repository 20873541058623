<template>
  <div class="purchasePlanInfoPageBox page-info-content">
    <DetailsPage :details-item-arr="detailsItemArr" :detail-obj="detailObj">
      <template>
        <el-descriptions class="slot-content" :column="2">
          <el-descriptions-item
            v-if="
              detailObj.ladderConfigList && detailObj.ladderConfigList.length
            "
            :span="2"
            label="阶梯费用"
          >
            <Table
              :list-data="detailObj.ladderConfigList || []"
              :item-data="rateLadderItemData"
            />
          </el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="labelClassName">
            <h3 class="page-subtitle-shade">
              计划代采货物
            </h3>
          </el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="labelClassName">
            <Table
              :list-data="detailObj.agentPlanGoodsList || []"
              :item-data="itemData"
            />
          </el-descriptions-item>
          <el-descriptions-item :span="2" label-class-name="labelClassName">
            <h3 class="page-subtitle-shade">
              预计采购费用
            </h3>
          </el-descriptions-item>
          <el-descriptions-item label="货款总额(元)">
            {{ detailObj.totalPayment }}
          </el-descriptions-item>
          <el-descriptions-item label="代采费用(元)">
            {{ detailObj.estimateCost }}
          </el-descriptions-item>
          <el-descriptions-item label="保证金比例(%)">
            {{ detailObj.depositRate }}
          </el-descriptions-item>
          <el-descriptions-item label="保证金金额(元)">
            {{ detailObj.deposit }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="2"
            label-class-name="labelClassName"
            label="预计代采费用"
          >
            <h3 class="page-subtitle-shade">
              保证金明细
            </h3>
          </el-descriptions-item>
          <el-descriptions-item
            :span="2"
            label-class-name="labelClassName"
            label="预计代采费用"
          >
            <Table
              :list-data="detailObj.depositDetailList"
              :item-data="bondItemData"
            />
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </DetailsPage>
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage'
import Table from '@/components/Table.vue'
import { agentPurchasePlanDetail } from '@/api/generationMining'
export default {
  components: {
    DetailsPage,
    Table
  },
  data() {
    return {
      breadcrumbItem: [
        { path: '/', name: '首页' },
        { path: '/purchasePlanManage/index', name: '采购计划管理' },
        { path: '', name: '采购计划详情' }
      ],
      detailObj: {
        transType: [],
        paymentConfigList: [],
        agentPlanGoodsList: []
      },
      rules: {},
      bondItemData: [
        { label: '付款日期', prop: 'payableDate' },
        { label: '付款比例', prop: 'paymentRate', unit: '%' },
        { label: '保证金金额(元)', prop: 'depositPayable', type: 'money' }
      ],
      rateLadderItemData: [
        { label: '价格下限', prop: 'lowerLimit' },
        { label: '价格上限', prop: 'upperLimit' },
        { label: '基数(元/吨)', prop: 'base' },
        { label: '规则', prop: 'operFormula' }
      ],
      itemData: [
        { label: '品名', prop: 'goodsName' },
        { label: '材质', prop: 'goodsMaterials' },
        { label: '规格', prop: 'goodsSpecs' },
        { label: '产地', prop: 'goodsProduct' },
        { label: '件重(吨)', prop: 'goodsWeights' },
        { label: '采购数量(吨)', prop: 'purchaseWeight' },
        { label: '采购单价(元/吨)', prop: 'purchasePrice', type: 'money' },
        { label: '采购单位', prop: 'purchaseUnit', child: this.$store.getters.getDictionaryItem('purchaseUnit') },
        { label: '金额小计(元)', prop: 'totalPrice', type: 'money' }
      ],
      detailsItemArr: [
        { label: '采购计划单号', value: 'planNo' },
        { label: '合作企业', value: 'supplierCmpName' },
        { label: '代采机构', value: 'fundsCmpName' },
        {
          label: '状态',
          value: 'planStatus',
          child: this.$store.getters.getDictionaryItem('planStatus')
        },
        { label: '代采费率', value: 'rateValue' },
        { label: '逾期费率', value: 'overdueInterestRate', rightWords: '%/天' },
        { label: '采购期限', value: 'maxPurchaseDays', rightWords: '天' },
        { label: '货款延期支付天数', value: 'deferredPaymentDays' },
        { label: '交收仓库', value: 'deliveryWarehouseName' },
        { label: '交货地址', value: 'deliverWarehouseAddr' },
        {
          label: '交货方式',
          value: 'deliveryType',
          child: [
            { id: 1, val: '卖家配送' },
            { id: 2, val: '买家自提' },
            { id: 3, val: '直发' }
          ]
        },
        {
          label: '结算日期',
          value: 'settleDate',
          rightWords: '号',
          leftWords: '每月'
        },
        {
          label: '开票方式',
          value: 'invoicingType',
          child: [
            { id: 1, val: '一票制' },
            { id: 2, val: '多票制' }
          ]
        },
        { label: '交易类型', value: 'transType2' },
        { label: '计划代采天数', value: 'purchaseDays', rightWords: '天' },
        { label: '备注', value: 'remark' }

        // { label: '货款总额', money: 'totalPayment', rightWords: '元' },
        // { label: '代采费用(元)', value: 'estimateCost', rightWords: '元' },
        // { label: '保证金比例', value: 'depositRate', rightWords: '%' },
        // { label: '保证金金额', money: 'deposit', rightWords: '元' }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails(this.$route.query.id)
    }
  },
  methods: {
    getDetails(id) {
      agentPurchasePlanDetail({ planId: id }, (res) => {
        if (res.data.agentPlanGoodsList) {
          res.data.agentPlanGoodsList.forEach(item => {
            item.goodsSpecs = item.goodsSpecs === '-1' ? '混合规格' : item.goodsSpecs
            item.goodsProduct = item.goodsProduct === '-1' ? '不限' : item.goodsProduct
            item.goodsWeights = item.goodsWeights === -1 ? '不限' : item.goodsWeights
          })
        }
        this.detailObj = { ...res.data }
        this.detailObj.transType2 =
          this.detailObj.transType === 1 ? '锁量' : '锁价'
        this.detailObj.rateValue = ''
        if (res.data.agentRateConfig) {
          switch (res.data.agentRateConfig.rateUnit) {
            case 1:
              this.detailObj.rateValue =
                res.data.agentRateConfig.rateValue + '%'
              break
            case 2:
              this.detailObj.rateValue =
                res.data.agentRateConfig.rateValue + '元/吨'
              break
            case 3:
              this.detailObj.rateValue =
                res.data.agentRateConfig.rateValue + '元/吨'
              break
            default:
              break
          }
          switch (res.data.agentRateConfig.rateCycle) {
            case 1:
              this.detailObj.rateValue += '/天'
              break
            case 2:
              this.detailObj.rateValue += '/周'
              break
            case 3:
              this.detailObj.rateValue += '/月'
              break
            case 4:
              this.detailObj.rateValue += '/年'
              break
            default:
              break
          }
        }
        this.detailObj.paymentConfigList = res.data.paymentConfigList || []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.purchasePlanInfoPageBox {
  transform: translateX(-30px);
  /deep/.el-descriptions__header {
    transform: translateX(30px);
  }
  .slot-content {
    padding-top: 0;
    /deep/.page-subtitle-shade {
      margin: 16px 0 18px;
    }
    /deep/.labelClassName {
      display: none;
    }

    /deep/ .el-descriptions-item__content {
      flex: 1;
    }
  }

  /deep/.detailsMargin {
    display: inline-block;
    width: 100px;
  }
}
</style>
